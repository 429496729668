import Axios from 'axios'
import { required } from "vuelidate/lib/validators";

export const SucursalServices = {
    //API
    api: {
        async find(id) {
            return Axios.get('sucursales/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('sucursales')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(sucursal) {
            return Axios.post('sucursales', sucursal)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, sucursal) {
            return Axios.put('sucursales/' + id, sucursal)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(sucursal) {
            return Axios.delete('sucursales/' + sucursal.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },
    //VALIDATIONS
    validations: {
        descripcion: { required },
    },
    //TABLELIST
    headers() {
        const header = [{ value: '#', sortable: true }, { value: 'Sucursal', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'id', class: 'text-primary', label: '#' }]
            },
            {
                class: 'important',
                data: [{ value: 'descripcion' }]
            }
        ]
        return body
    },
    dataList() {
        return [
            { name: "descripcion", class: "" }
        ]
    }

}